/*
Results actions
*/
export const SET_FILTERS = 'SET_FILTERS';
export const ADD_FILTER_SET = 'ADD_FILTER_SET';
export const REMOVE_FILTER_SET = 'REMOVE_FILTER_SET';

export const FETCHING_RESULTS = "FETCHING_RESULTS";
export const RESULTS_FETCH_SUCCESS = "RESULTS_FETCH_SUCCESS";
export const RESULTS_FETCH_FAILURE = "RESULTS_FETCH_FAILURE";

export const FETCHING_HISTORICAL_RESULTS = "FETCHING_HISTORICAL_RESULTS";
export const HISTORICAL_RESULTS_FETCH_SUCCESS = "HISTORICAL_RESULTS_FETCH_SUCCESS";
export const HISTORICAL_RESULTS_FETCH_FAILURE = "HISTORICAL_RESULTS_FETCH_FAILURE";

/*
Survey actions
*/
export const FETCHING_SURVEY = "FETCHING_SURVEY";
export const SURVEY_FETCH_SUCCESS = "SURVEY_FETCH_SUCCESS";
export const SURVEY_FETCH_FAILURE = "SURVEY_FETCH_FAILURE";

/*
Benchmark actions
*/
export const FETCHING_BENCHMARKS = "FETCHING_BENCHMARKS";
export const BENCHMARKS_FETCH_SUCCESS = "BENCHMARKS_FETCH_SUCCESS";
export const BENCHMARKS_FETCH_FAILURE = "BENCHMARKS_FETCH_FAILURE";
export const GET_BENCHMARK_DATA = "GET_BENCHMARK_DATA";

/*
Enrichment Center actions
*/
export const FETCHING_EC_CONTENT = "FETCHING_EC_CONTENT";
export const EC_CONTENT_FETCH_SUCCESS = "EC_CONTENT_FETCH_SUCCESS";
export const EC_CONTENT_FETCH_FAILURE = "EC_CONTENT_FETCH_FAILURE";

export const FETCHING_TIME_WATCHED = "FETCHING_TIME_WATCHED";
export const TIME_WATCHED_FETCH_SUCCESS = "TIME_WATCHED_FETCH_SUCCESS";
export const TIME_WATCHED_FETCH_FAILURE = "TIME_WATCHED_FETCH_FAILURE";

export const UPDATE_TIME_WATCHED = "UPDATE_TIME_WATCHED";

export const FETCHING_EC_CATEGORIES = "FETCHING_EC_CATEGORIES";
export const EC_CATEGORIES_FETCH_SUCCESS = "EC_CATEGORIES_FETCH_SUCCESS";
export const EC_CATEGORIES_FETCH_FAILURE = "EC_CATEGORIES_FETCH_FAILURE";

export const FETCHING_EC_SECTIONS = "FETCHING_EC_SECTIONS";
export const EC_SECTIONS_FETCH_SUCCESS = "EC_SECTIONS_FETCH_SUCCESS";
export const EC_SECTIONS_FETCH_FAILURE = "EC_SECTIONS_FETCH_FAILURE";

export const FETCHING_EC_CONTENT_TO_SECTIONS = "FETCHING_EC_CONTENT_TO_SECTIONS";
export const EC_CONTENT_TO_SECTIONS_FETCH_SUCCESS = "EC_CONTENT_TO_SECTIONS_FETCH_SUCCESS";
export const EC_CONTENT_TO_SECTIONS_FETCH_FAILURE = "EC_CONTENT_TO_SECTIONS_FETCH_FAILURE";

export const CLAIMING_CME_CREDIT = "CLAIMING_CME_CREDIT";
export const CLAIM_CME_CREDIT_SUCCESS = "CLAIM_CME_CREDIT_SUCCESS";
export const CLAIM_CME_CREDIT_FAILURE = "CLAIM_CME_CREDIT_FAILURE";

export const FETCHING_CME_CREDIT = "FETCHING_CME_CREDIT";
export const CME_CREDIT_FETCH_SUCCESS = "CME_CREDIT_FETCH_SUCCESS";
export const CME_CREDIT_FETCH_FAILURE = "CME_CREDIT_FETCH_FAILURE";

export const CLAIMING_CONTACT_HOURS = "CLAIMING_CONTACT_HOURS";
export const CLAIM_CONTACT_HOURS_SUCCESS = "CLAIM_CONTACT_HOURS_SUCCESS";
export const CLAIM_CONTACT_HOURS_FAILURE = "CLAIM_CONTACT_HOURS_FAILURE";

export const FETCHING_CONTACT_HOURS = "FETCHING_CONTACT_HOURS";
export const CONTACT_HOURS_FETCH_SUCCESS = "CONTACT_HOURS_FETCH_SUCCESS";
export const CONTACT_HOURS_FETCH_FAILURE = "CONTACT_HOURS_FETCH_FAILURE";

export const UPDATE_TOAST_NOTIFICATION = "SENDING_TOAST_NOTIFICATION";

export const FETCHING_PULSE_CHECK_SURVEY = "FETCHING_PULSE_CHECK_SURVEY"
export const PULSE_CHECK_SURVEY_FETCH_SUCCESS = "PULSE_CHECK_SURVEY_FETCH_SUCCESS"
export const PULSE_CHECK_SURVEY_FETCH_FAILURE = "PULSE_CHECK_SURVEY_FETCH_FAILURE"

export const SUBMITTING_PULSE_CHECK_SURVEY_RESPONSE = "SUBMITTING_PULSE_CHECK_SURVEY_RESPONSE"
export const PULSE_CHECK_SURVEY_RESPONSE_SUCCESS = "PULSE_CHECK_SURVEY_RESPONSE_SUCCESS"
export const PULSE_CHECK_SURVEY_RESPONSE_FAILURE = "PULSE_CHECK_SURVEY_RESPONSE_FAILURE"

export const SET_SHOW_PULSE_CHECK_SURVEY = "SET_SHOW_PULSE_CHECK_SURVEY";

/*
Authentication actions
*/
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const DISMISS_ERROR = "DISMISS_ERROR";
export const DISMISS_SUCCESS = "DISMISS_SUCCESS";
export const LOGOUT = "LOGOUT";

export const REQUESTING_PASSWORD_RESET = "REQUESTING_PASSWORD_RESET";
export const REQUEST_PASSWORD_RESET_FAILURE = "REQUEST_PASSWORD_RESET_FAILURE";
export const REQUEST_PASSWORD_RESET_SUCCESS = "REQUEST_PASSWORD_RESET_SUCCESS";

export const VALIDATING_TOKEN = "VALIDATING_TOKEN";
export const TOKEN_VALIDATION_SUCCESS = "TOKEN_VALIDATION_SUCCESS";
export const TOKEN_VALIDATION_FAILURE = "TOKEN_VALIDATION_FAILURE";

export const RESETTING_PASSWORD = "RESETTING_PASSWORD";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";

export const CHECKING_CONFIRMATION_TOKEN = "CHECKING_CONFIRMATION_TOKEN";
export const CONFIRMATION_TOKEN_VALID = "CONFIRMATION_TOKEN_VALID";
export const CONFIRMATION_TOKEN_ERROR = "CONFIRMATION_TOKEN_ERROR";

export const SETTING_PASSWORD = "SETTING_PASSWORD";
export const PASSWORD_SET_SUCCESS = "PASSWORD_SET_SUCCESS";
export const PASSWORD_SET_FAILURE = "PASSWORD_SET_FAILURE";

export const CREATING_USER = "CREATING_USER";
export const USER_CREATION_SUCCESS = "USER_CREATION_SUCCESS";
export const USER_CREATION_FAILURE = "USER_CREATION_FAILURE";

export const GETTING_USER = "GETTING_USER";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAILURE = "USER_GET_FAILURE";

export const UPDATING_USER = "UPDATING_USER";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

export const UPDATE_ACCESS_TOKEN = " UPDATE_ACCESS_TOKEN";

export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";

export const SUCCESS_MESSAGE = "SUCCESS_MESSAGE";

export const UPDATING_SHOW_MOBILE_MODAL = "UPDATING_SHOW_MOBILE_MODAL"
/*

Burnout Blocks actions
*/
export const FETCHING_BURNOUT_BLOCKS = "FETCHING_BURNOUT_BLOCKS";
export const BURNOUT_BLOCKS_FETCH_SUCCESS = "BURNOUT_BLOCKS_FETCH_SUCCESS";
export const BURNOUT_BLOCKS_FETCH_FAILURE = "BURNOUT_BLOCKS_FETCH_FAILURE";
export const SELECT_BURNOUT_BLOCK = "SELECT_BURNOUT_BLOCK";
export const RESET_BURNOUT_BLOCK = "RESET_BURNOUT_BLOCK";

/*
Group actions
 */
export const FETCHING_GROUPS = "FETCHING_PRACTICE_GROUPS";
export const GROUPS_FETCH_SUCCESS = "GROUPS_FETCH_SUCCESS";
export const GROUPS_FETCH_FAILURE = "GROUPS_FETCH_FAILURE";
export const SELECT_GROUP = "SELECT_GROUP";
export const RESET_GROUP = "RESET_GROUP";

/*
Comments actions
*/
// export const TOGGLE_COMMENT_VISIBILITY = "TOGGLE_COMMENT_VISIBILITY";
export const FETCHING_COMMENTS_ANALYSIS = "FETCHING_COMMENTS_ANALYSIS";
export const COMMENTS_ANALYSIS_FETCH_SUCCESS = "COMMENTS_ANALYSIS_FETCH_SUCCESS";
export const COMMENTS_ANALYSIS_FETCH_FAILURE = "COMMENTS_ANALYSIS_FETCH_FAILURE";
export const SET_COMMENTS_FILTERS = 'SET_COMMENTS_FILTERS';


/*
Feedback Modal actions
 */
// export const GIVING_FEEDBACK = "GIVING_FEEDBACK";
export const SUBMITTING_FEEDBACK = "SUBMITTING_FEEDBACK";
export const SUBMIT_FEEDBACK_SUCCESS = "SUBMIT_FEEDBACK_SUCCESS";
export const SUBMIT_FEEDBACK_FAILURE = "SUBMIT_FEEDBACK_FAILURE";
export const ADD_WATCHED_VIDEO = "ADD_WATCHED_VIDEO";
export const TOGGLE_FEEDBACK = "TOGGLE_FEEDBACK";
export const RESET_WATCHED_VIDEOS = "RESET_WATCHED_VIDEOS";

export const SET_SORT_VALUE = 'SET_SORT_VALUE';

/*
Resend Survey actions
 */
export const RESENDING_SURVEY = "RESENDING_SURVEY";
export const RESENT_SURVEY_SUCCESS = "RESENT_SURVEY_SUCCESS";
export const RESENT_SURVEY_FAILURE = "RESENT_SURVEY_FAILURE";